import React, { useState } from 'react'
import { pick, sum, values, keys } from 'ramda'
import {
  ABFee,
  highEarnerTaxableBreakPoint,
  CompanyType,
  evaluateTaxAndFees,
} from '@Utils/Helpers/calculatorHelpers'
import { BlobText } from '@Components/BlobText'
import { useEpiString } from '@Hooks/useEpiStrings'
import { useWizard } from '@trr/wizard-library'
import { householdExpensesDefaults } from '@Features/Calculations/HouseholdExpenses'
import { formDataType } from '@Features/Calculator/Calculator'
import { companyExpensesDefaults } from '@Features/Calculations/CompanyExpenses'
import { formatCurrency, formatPercentage } from '@Utils/Helpers'
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { ExtendedWizardStep } from '@Components/ExtendedWizardStep/ExtendedWizardStep'
import { useNavigationGtmTracker, useClickGtmTracker } from '@Hooks/gtm'
import parse from 'html-react-parser'

import LabelWithValueItem from '../../Components/LabelWithValueItem/LabelWithValueItem'
import ColorPulse from '../../Components/ColorPulse/ColorPulse'

interface ResultsSummaryProps {
  formValues: formDataType
}

const BoldedText = styled(Typography)(({ theme }) => ({
  ['& span']: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  ['& .black']: {
    color: theme.palette.text.primary,
  },
}))

const OLD_CCL_ORANGE = '#cc4900'

export const ResultsSummary: React.FunctionComponent<ResultsSummaryProps> = ({
  formValues,
}) => {
  const t = useEpiString()
  const { previousStep } = useWizard()
  const { trackButtonClick } = useClickGtmTracker()
  const { trackPreviousStep } = useNavigationGtmTracker()

  const handleGoBack = () => {
    trackPreviousStep()
    previousStep()
  }

  const [companyFee, setCompanyFee] = useState<{
    type: CompanyType
    touched: boolean
  }>({ type: CompanyType.EF, touched: false })

  const [wishedIncome, setWishedIncome] = useState<{
    value: number
    touched: boolean
  }>({ value: formValues.wishedIncome, touched: false })

  const isEF = companyFee.type === CompanyType.EF

  const householdExpenses = pick(keys(householdExpensesDefaults), formValues)

  const companyExpenses = pick(keys(companyExpensesDefaults), formValues)

  const householdExpensesSum = sum(
    values(pick(keys(householdExpensesDefaults), formValues))
  )

  const cashLeftPromising = householdExpensesSum * 0.35
  const cashLeftUnsafe = householdExpensesSum * 0.25

  const {
    salaryMinusPrivateCosts,
    taxableSums,
    wishedIncomeWithPaidTaxes,
    workProviderFeeToPay,
    hourlyRateToAchieveIncome,
    totalMonthlyNeedInvoice,
    companyExpensesSum,
    billedHoursPerMonth,
  } = evaluateTaxAndFees(
    wishedIncome.value,
    companyFee.type,
    formValues.taxPercentage,
    formValues.estimatedWorkLoad,
    formValues.vacationDays,
    sum(values(companyExpenses)),
    sum(values(householdExpenses))
  )

  const evaluatedHeader = (cashLeft: number) => {
    if (sum(values(householdExpenses)) <= 0) {
      return t('headingNoHouseholdExpenses')
    }
    if (cashLeft < cashLeftUnsafe) {
      return t('headingWarning')
    }
    if (cashLeft < cashLeftPromising) {
      return t('headingSemiWarning')
    }
    return t('heading1')
  }

  const getBlobUrl = (cashLeft: number) => {
    if (cashLeft < cashLeftUnsafe) {
      return t('blobWarningUrl')
    }
    if (cashLeft < cashLeftPromising) {
      return t('blobSemiWarningUrl')
    }
    return t('blob2Url')
  }

  const formatTooltip = (value: number) => `${formatCurrency(value)}`

  const handleSliderChange = (_event: Event, value: number) => {
    if (!wishedIncome.touched) {
      trackButtonClick('Företagskalkyl: Resultat', {
        label: 'Interaktion med reglaget för önskad lön',
      })
    }
    setWishedIncome({ value, touched: true })
  }

  return (
    <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} minWidth="50vw">
      <ExtendedWizardStep
        prevButton={{
          onClick: handleGoBack,
          text: t('backButtonText'),
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h3">
            {evaluatedHeader(salaryMinusPrivateCosts)}
          </Typography>
          <BoldedText variant="body2">
            {parse(
              t('ingress', {
                wishedIncome: formatCurrency(wishedIncome.value),
                hourlyRateToAchieveIncome: formatCurrency(
                  hourlyRateToAchieveIncome
                ),
                totalMonthlyNeedInvoice: formatCurrency(
                  totalMonthlyNeedInvoice
                ),
                billedHoursPerMonth: String(billedHoursPerMonth),
              })
            )}
          </BoldedText>

          <FormGroup>
            <RadioGroup row>
              <FormControlLabel
                label={t('companyTypeEFRadio')}
                control={<Radio size="small" />}
                componentsProps={{
                  typography: { variant: 'body2' },
                }}
                onChange={() =>
                  setCompanyFee({ type: CompanyType.EF, touched: true })
                }
                checked={companyFee.type === CompanyType.EF}
              />
              <FormControlLabel
                label={t('companyTypeABRadio')}
                control={<Radio size="small" />}
                componentsProps={{
                  typography: { variant: 'body2' },
                }}
                onChange={() =>
                  setCompanyFee({ type: CompanyType.AB, touched: true })
                }
                checked={companyFee.type === CompanyType.AB}
              />
            </RadioGroup>
          </FormGroup>

          <Typography variant="body2" htmlFor={'RateSlider'} component="label">
            {parse(
              t('sliderDescription', {
                householdExpensesSum: formatCurrency(householdExpensesSum),
              })
            )}
          </Typography>

          <Slider
            id="RateSlider"
            max={formValues.wishedIncome * 1.5}
            min={
              householdExpensesSum < formValues.wishedIncome
                ? householdExpensesSum
                : formValues.wishedIncome
            }
            value={wishedIncome.value}
            onChange={handleSliderChange}
            step={500}
            valueLabelDisplay="on"
            marks={[{ label: 'Önskad lön', value: formValues.wishedIncome }]}
            valueLabelFormat={formatTooltip}
          />

          <Stack>
            <Box sx={{ alignSelf: 'flex-start' }}>
              <BlobText
                textColor={'#fff'}
                backgroundUrl={t('blob1Url')}
                heading={`${formatCurrency(hourlyRateToAchieveIncome)}`}
                description={t('hourlyRate')}
              />
            </Box>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <BlobText
                textColor={'#fff'}
                backgroundUrl={getBlobUrl(salaryMinusPrivateCosts)}
                heading={formatCurrency(salaryMinusPrivateCosts)}
                description={t('salaryLeftAfterPrivateExpenses')}
              />
            </Box>
          </Stack>
        </Stack>
      </ExtendedWizardStep>

      <Stack spacing={2}>
        <Typography variant="h5" gutterBottom>
          {t('calculationHeading')}
        </Typography>
        <Typography component="p" variant="subtitle1" gutterBottom>
          {t('calculationDescription')}
        </Typography>
        <LabelWithValueItem
          label={t('hourlyRateNeeded')}
          value={formatCurrency(hourlyRateToAchieveIncome)}
          bold
        />
        <LabelWithValueItem
          label={t('monthlyInvoiceNeeded')}
          value={formatCurrency(totalMonthlyNeedInvoice)}
          bold
        />

        {!isEF && (
          <ColorPulse
            pulseColor={OLD_CCL_ORANGE}
            disabled={!companyFee.touched}
          >
            <LabelWithValueItem
              label={t('abFees', {
                abFee: formatPercentage(ABFee * 100),
              })}
              value={`${workProviderFeeToPay === 0 ? '' : '-'}${formatCurrency(
                workProviderFeeToPay
              )}`}
            />
          </ColorPulse>
        )}

        <LabelWithValueItem
          label={t('companyExpenses')}
          value={`${companyExpensesSum === 0 ? '' : '-'}${formatCurrency(
            companyExpensesSum
          )}`}
        />
        <LabelWithValueItem
          label={isEF ? t('salaryCosts.EF') : t('salaryCosts')}
          value={formatCurrency(wishedIncomeWithPaidTaxes)}
          bold
        />
        <ColorPulse
          pulseColor={OLD_CCL_ORANGE}
          disabled={!isEF || !companyFee.touched}
        >
          <LabelWithValueItem
            label={isEF ? t('taxes.EF') : t('taxes')}
            value={`${
              taxableSums.normalIncomeTaxToPay === 0 ? '' : '-'
            }${formatCurrency(taxableSums.normalIncomeTaxToPay)}`}
          />
        </ColorPulse>
        <LabelWithValueItem
          label={t('highEarnerTax', {
            highEarnerTaxableBreakPoint: formatCurrency(
              highEarnerTaxableBreakPoint
            ),
          })}
          value={`${
            taxableSums.highIncomeTaxToPay === 0 ? '' : '-'
          }${formatCurrency(taxableSums.highIncomeTaxToPay)}`}
        />
        <LabelWithValueItem
          label={isEF ? t('wishedIncome.EF') : t('wishedIncome')}
          value={formatCurrency(wishedIncome.value)}
          bold
        />
        <LabelWithValueItem
          label={t('personalExpenses')}
          value={`${householdExpensesSum === 0 ? '' : '-'}${formatCurrency(
            householdExpensesSum
          )}`}
        />
        <LabelWithValueItem
          label={t('remainingInWallet')}
          value={formatCurrency(salaryMinusPrivateCosts)}
          bold
        />
        <Divider />
        <Typography variant="subtitle2" whiteSpace="pre-line">
          {isEF ? t('calculationDetailsEf') : t('calculationDetailsAb')}
        </Typography>
      </Stack>
    </Stack>
  )
}
