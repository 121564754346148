import React from 'react'
import { Stack, Typography, styled } from '@mui/material'

interface BlobTextProps {
  heading: string
  description: string
  backgroundUrl: string
  textColor: string
}

const BlobWrapper = styled(Stack)<{ bgImage: string; textColor: string }>(
  ({ bgImage, textColor }) => ({
    width: '245px',
    height: '184px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    color: textColor,
    backgroundImage: `url("${bgImage}")`,
  })
)

export const BlobText: React.FC<BlobTextProps> = ({
  heading,
  description,
  backgroundUrl,
  textColor = 'fffff',
}) => (
  <BlobWrapper
    textColor={textColor}
    bgImage={backgroundUrl}
    spacing={0}
    alignItems="center"
    justifyContent="center"
  >
    <Typography component="p" variant="h4">
      {heading}
    </Typography>
    <Typography variant="body1">{description}</Typography>
  </BlobWrapper>
)
